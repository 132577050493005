import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';

const ContactPage = ({data}) => {
  const {
    markdownRemark: { 
          frontmatter,
          html
        }
  } = data;
  return (
    <Layout pageName="Contact">
      <div className="contact-container">
        <div className="contact-container-text">
          <h1>{frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
          <div className="contact-container-links">
            <div className="contact-container-links-wrapper">
              <div className="contact-container-links-wrapper-city">
                <span className="contact-container-links-wrapper-city-label">basée à</span>
                <span className="contact-container-links-wrapper-city-item">{frontmatter.city}</span>
              </div>
            </div>
            <div className="contact-container-links-wrapper">
              <div className="contact-container-links-wrapper-phone">
                <span className="contact-container-links-wrapper-phone-label">téléphone</span>
                <a className="contact-container-links-wrapper-phone-item" href={`tel:${frontmatter.tel}`}>{frontmatter.tel}</a>
              </div>
            </div>
            <div className="contact-container-links-wrapper">
              <div className="contact-container-links-wrapper-mail">
                <span className="contact-container-links-wrapper-mail-label">e-mail</span>
                <a className="contact-container-links-wrapper-mail-item" href={`mailto:${frontmatter.mail}`}>{frontmatter.mail}</a>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export const contactQuery = graphql`
{
  markdownRemark(
    fileAbsolutePath: {
      regex: "/content/contact/"
    }
  ) {
    html
    frontmatter {
      title
      mail
      tel
      city
    }
  }
}
`
export default ContactPage;